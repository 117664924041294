.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 300ms ease-in-out;
	overflow-y: scroll;
}
.ReactModal__Overlay .ReactModal__Content {
	transform: translateY(2rem);
	transition: transform 300ms ease-in-out;
}



.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--after-open .ReactModal__Content {
	transform: translateY(0rem);
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}

.ReactModal__Overlay--before-close .ReactModal__Content {
	transform: translateY(0rem);
}

body.ReactModal__Body--open{
	overflow: hidden;
	padding-right: 5px;
}