html {
	font-size: 10px;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-y: scroll;
}

@media print {
	 .main > div:not(.positions-to-print) {
		display: none !important;
	}
	@page {
		size: auto !important;
	}
	.positions-to-print{
		width: 100%;
		max-width: 1200px;
		opacity: 1 !important;
		display: block !important;
		visibility: visible !important;
		
	}
	.positions-to-print .product-table th{
		font-size: 15px;
	}
	.positions-to-print .product-table td{
		font-size: 15px;
	}
	body{
		width: 1200px;
		zoom: 0.85 !important;
		min-width: 320px;
	}
}

@media (min-width: 1024px) {
	body::-webkit-scrollbar {
		width: 5px; /* ширина для вертикального скролла */
		height: 5px; /* высота для горизонтального скролла */
		border-radius: 0px;
		background-color: #f5f5f5;
	}
	body::-webkit-scrollbar-thumb {
		background-color: #bdbdbd;
		border-radius: 10em;
	}

	.overflow-auto::-webkit-scrollbar {
		width: 5px; /* ширина для вертикального скролла */
		height: 15px; /* высота для горизонтального скролла */
		border-radius: 0px;
		background-color: #f5f5f5;
	}

	.overflow-auto::-webkit-scrollbar-thumb {
		background-color: #bdbdbd;
		border-radius: 10em;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (max-width: 1680px) {
	html {
		font-size: 0.625vw;
	}
}

@media (max-width: 1024px) {
	html {
		font-size: 10px;
	}
}

[tabindex="0"]:focus-visible {
	outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.sticker-to-print {
	display: flex;
	align-items: center;
	justify-content: center;
}

.sticker-to-print canvas {
	max-height: 100%;
	max-width: 100%;
	image-rendering: pixelated;
}

.react-datepicker-popper{
	z-index: 100;
}
#ready-picker-portal{
	font-size: 18px;
	position:relative;
	z-index: 1000;
}
#ready-picker-portal .react-datepicker-popper{
	z-index: 100;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
